:root {
	--theme-deafult: #98161b;
	--theme-secondary: #6e073f;
	--theme-tertiary: #f7f3eb;
	--theme-deafult-rgb: 149, 209, 45;
	--theme-secondary-rgb: 155, 155, 155;
	--theme-tertiary-rgb: 247, 243, 235;
	--theme-deafult-darker: #6e073f;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.style_valuta{
	font-family: 'Poppins', sans-serif !important;
}
//#region css dashboard starter
.left-header .level-menu .header-level-menu.show {
	height: auto !important;
}

.left-header .level-menu .header-level-menu {
	width: 220px !important;
}

.left-header .level-menu .nav-link {
	background-color: rgba(115, 102, 255, 0.15);
	color: var(--theme-deafult);
}

.left-header .level-menu .nav-link svg {
	stroke: var(--theme-deafult);
}

.page-wrapper.compact-wrapper
.page-body-wrapper
div.sidebar-wrapper
.logo-wrapper,
.page-wrapper.compact-wrapper
.page-body-wrapper
div.sidebar-wrapper
.logo-icon-wrapper {
	padding: 7px 31px;
}

.img-main-news {
	min-height: 239px !important;
}

.cal-desc-main-news {
	min-height: 142px;
	overflow: hidden;
}

.cal-desc-main-news-more > a {
	display: block;
	padding: 20px 0px;
}

.calender-widget .cal-img {
	background: none;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

#box_news .img-main-news {
	background: url("/public/assets/images/news_main.jpg");
	background-size: cover;
}

.card .card-body {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 40px;
	padding-right: 40px;
}

.typehead_utente_dashboard {
	background-color: transparent;
	font-size: 24px;
	font-weight: 500;
	color: black;
	border: none;
	padding-top: 0px;
	line-height: 0;
}

#typehead_ID_utente {
	float: right;
	margin-top: -2px;
	//border-bottom: 1px solid #ced4da;
}

body.dark-only #typehead_ID_utente {
	border-bottom: 1px solid #374558;
}

#typehead_ID_utente i {
	color: #ced4da;
}

#typehead_ID_utente .input-group-text {
	border: none;
	background: transparent;
}

#typehead_ID_cliente {
	float: right;
	margin-top: -2px;
	border-bottom: 1px solid #ced4da;
}

body.dark-only #typehead_ID_cliente {
	border-bottom: 1px solid #374558;
}

#typehead_ID_cliente i {
	color: #ced4da;
}

#typehead_ID_cliente .input-group-text {
	border: none;
	background: transparent;
}

#div_chart_filters {
	padding-top: 15px;
}

#div_chart_filters .col-md-6 {
	float: left;
	padding: 15px;
}

.earning-card.card .card-body .chart-left {
	padding: 10px 0 10px 40px;
}

.earning-card.card .card-body .chart-right .p-tb {
	padding: 18px 0;
}

.earning-card.card .card-body .border-top {
	border-top: 1px solid #ecf3fa !important;
	padding: 21px 17px 22px 34px;
}

#div_totali {
	text-align: center;
}

#div_totali h4 {
	font-size: 23px;
	margin-top: 10px;
	margin: auto;
}

#div_totali a {
	font-weight: 200;
}

.div_totali_value {
	height: 60px;
	/*display: table-cell;*/
	display: flex;
	vertical-align: middle;
	width: 100%;
}

.div_totali_text {
	height: 60px;
	display: table-cell;
	vertical-align: middle;
	color: #adadad;
}

.chart_data_left .card-body .chart-main .media,
.chart_data_right .card-body .chart-main .media {
	padding: 30px 40px;
}

#div_totali .col-md-3 {
	float: left;
}

#div_totali .col-md-6 {
	float: left;
}

.chart_data_left .card-body .chart-main .media .media-body .right-chart-content,
.chart_data_right
.card-body
.chart-main
.media
.media-body
.right-chart-content {
	text-align: -webkit-center;
}

.chart_data_left .card-body .chart-main .media,
.chart_data_right .card-body .chart-main .media {
	padding: 30px 10px;
}

#typehead_sottoposti .tt-suggestion {
	font-size: 15px !important;
	padding: 6px 12px;
}

.sidebar-main-title{
	border-top: 1px solid #282827 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li.sidebar-main-title {
	padding-bottom: 0px !important;
}

.page-wrapper .sidebar-main-title > div {
	background-color: rgba(var(--theme-tertiary-rgb), 1) !important;
}

.left-header .level-menu .nav-link {
	background-color: rgba(var(--theme-secondary-rgb), 0.07) !important;
}

.badge-info {
	background-color: #2793f9;
}

.page-wrapper.compact-wrapper
.page-body-wrapper
div.sidebar-wrapper
.sidebar-main
.sidebar-links
.simplebar-wrapper
.simplebar-mask
.simplebar-content-wrapper
.simplebar-content
> li.sidebar-list:hover
> a:hover {
	background-color: rgb(246, 249, 254);
}

#top5_container {
	// padding-left: 20px !important;
	// padding-right: 20px !important;
	padding: 0px !important;
	padding-bottom: 15px !important;
}

#top5_container > ul {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

#top5_container > ul > li > a {
	padding: 10px !important
}

#top5-vendite-tab {
	//padding-left: 0px;
}

#top5_container td {
	padding: 0;
}

#top5_header {
	border-bottom: 1px solid #ffffff;
}

body.dark-only #top5_header {
	border-bottom: 1px solid #374558;
}

.top5_users_list hr {
	margin: 8px 0;
	background-color: #c6c8c9;
}

.span_qualifica_1 {
	padding: 4px 10px;
	border: 1px solid #0d6efd;
	border-radius: 10px;
	font-size: 90%;
	font-weight: 400 !important;
	letter-spacing: 1px;
	color: black !important;
}

.span_qualifica_2 {
	padding: 4px 10px;
	border: 1px solid green;
	border-radius: 10px;
	font-size: 90%;
	font-weight: 400 !important;
	letter-spacing: 1px;
	color: black !important;
}

.span_qualifica_3 {
	padding: 4px 10px;
	border: 1px solid #ffcc00;
	border-radius: 10px;
	font-size: 90%;
	font-weight: 400 !important;
	letter-spacing: 1px;
	color: black !important;
}

.indent_15 {
	padding-left: 15px;
}

#carriera_container hr {
	background: #b8bcbf;
}

.opacity-50 {
	opacity: 0.5;
}

#container_page_ingresso .row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap; //vanno a capo
	align-items: stretch;
}

#container_page_ingresso .row > [class*="div-long-"] {
	display: flex !important;
	flex-direction: column !important;
}

#container_page_ingresso .row > [class*="div-side-"] {
	display: flex !important;
	flex-direction: column !important;
	//custom > 1660 : flex-direction: row forzato
}

#container_page_ingresso .row > [class*="div-box-"] {
	display: flex !important;
	flex-direction: column !important;
	align-self: stretch !important;
}

.height_100 {
	height: 100%;
}

.width_100 {
	width: 100%;
}

.width_50 {
	width: 50%;
}

.color-gray {
	color: #6C757D !important;
}

#div_sponsor h6 {
	margin-bottom: 10px;
}

#div_sponsor{
	padding: 0 !important;
}

.earning-card.card .card-body .inner-top-right ul li:before {
	left: -10%;
}

.earning-card.card .card-body .inner-top-left ul li,
.earning-card.card .card-body .inner-top-right ul li {
	margin-left: 24px;
}

#div_chart_filters .float-start {
	padding-right: 4px;
}

.card .card-header > span {
	font-size: 14px;
}

.overflow_x_hidden {
	overflow-x: hidden !important;
}

table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: 600 !important;
	font-size: 14px !important;
	color: var(--bs-gray-dark) !important;
	font-family: "Roboto";
}

table.dataTable td {
	font-size: 14px !important;
	//color: #646c9a !important;
	color: var(--bs-gray-dark) !important;
	font-family: "Roboto";
}

#filter_button_datatable {
	border: 1px solid #efefef;
	padding: 10px;
	width: fit-content;
	/*position:absolute;*/
	top: 0px;
	cursor: pointer;
}

#filter_button_datatable:hover {
	background: #fafafa;
}

#datatable_responsive_orders_filter label {
	visibility: visible;
	height: auto;
	overflow: unset;
}

#applied_filters_datatable .badge {
	margin: 5px;
	font-weight: 500 !important;
}

#table_filters {
	width: 100%;
}

#table_filters td:nth-child(1) {
	width: 110px;
	padding-right: 10px;
}

.typehead_utente_dashboard_filtri {
	font-size: inherit !important;
}

.typehead_cliente_dashboard_filtri {
	font-size: inherit !important;
}

.select2-selection {
	width: 100%;
	padding: inherit;
	font-size: 1rem;
	font-weight: 400;
	line-height: 0.5;
	padding-left: 8px;
}

#filters_datatable .select2-selection__choice {
	padding: 2px 2px !important;
	margin-top: 3px !important;
	// background-color: #d2d2d2 !important;
	// color: #333 !important;
	// color: var(--theme-deafult) !important;
	color: #212529 !important;
	background-color: #e9ecef !important;
	font-size: small !important;
	margin-right: 8px !important;
	border-color: transparent !important;
}

#filters_datatable .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	//color: var(--theme-deafult) !important;
	color: #212529 !important;
}

#filters_datatable input {
	font-size: 13px !important;
}

#filters_datatable {
	border: 1px solid #fafafa;
	padding: 15px;
	background: #fafafa;
	border-radius: 10px;
}

.select2-container--default.select2-container--focus
.select2-selection--multiple {
	line-height: 0.5 !important;
	border: 1px solid #ced4da !important;
	outline: 0 !important;
	min-height: 34px !important;
	/*padding-top: 6px !important;*/
}

.select2-search {
	line-height: 1.5 !important;
	outline: 0 !important;

	padding: 6px !important;
}

.select2-container--default .select2-selection--multiple {
	background-color: white !important;
	border: 1px solid #ced4da !important;
	border-radius: 4px !important;
	cursor: text !important;
	min-height: 34px !important;
}

.selection .select2-selection .select2-search__field {
	padding-top: 3px !important;
}

.datepicker--day-name {
	color: #9b9b9c !important;
	font-weight: normal;
}

#filters_datatable .select2-selection__choice {
	padding: 11px 8px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
	min-height: calc(100vh - 133px) !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
	color: rgb(255, 255, 255);
	background-color: var(--bs-gray) !important;
	border-color: var(--bs-gray) !important;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
	margin-left: 0px;
	color: var(--bs-gray);
}

#datatable_responsive_orders_container .btn-sm {
	padding-top: 3px !important;
	padding-bottom: 3px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.dt-ext td.dt-right {
	padding-right: 30px !important;
}

#applied_filters_datatable {
	position: relative;
}

#applied_filters_datatable_container {
	height: auto;
	overflow: unset;
	white-space: nowrap;
	position: relative;
	width: 100%;
	margin-top: 0px;
}

.static-top-widget h4 {
	line-height: 0.7 !important;
}

.typeahead {
	min-height: 40px;
}

.display-inherit {
	display: inherit;
}

#datatable_responsive_orders_container button i {
	padding-left: 4px;
}

#table_profile_general_data td:nth-child(2) {
	color: black;
	padding-left: 10px;
}

#btn-oblio {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-evenly;
	align-items: center;
}

#btn-oblio svg {
	margin-right: 10px;
}

select.form_control {
	appearance: auto !important;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
}

//	*** print ***
#div_header_print {
	display: none;
}

.not-printable {
	display: block;
}

#div_header_print table {
	width: 100%;
}

#div_header_print table td:nth-child(2) {
	color: gray;
	font-size: 11px;
}

.card .card-header h5 {
	font-size: 18px !important;
	font-weight: 400 !important;
	text-transform: none !important;
}

.card .card-header {
	padding-top: 30px;
	padding-bottom: 30px;
}

#div_profile_general_data {
	width: 100%;
}

#table_profile_general_data {
	margin-top: auto;
	float: none;
}

.total-users {
	.goal-chart {
		margin: -35px -15px -30px;

		.apexcharts-datalabels-group {
			text {
				font-weight: 700;

				&:last-child {
					fill: #666666;
				}
			}
		}
	}

	.goal-status {
		ul {
			li {
				+ li {
					margin-top: 30px;
					padding-top: 30px;
				}

				.goal-detail {
					margin-bottom: 30px;

					h6 {
						margin-bottom: 16px;
					}
				}
			}

			&:last-child {
				li {
					border-top: 1px solid #666666;
					margin-top: 27px;
					padding-top: 27px;
				}
			}
		}

		.rate-card {
			> ul {
				li {
					&:last-child {
						border-top: none;
					}
				}
			}
		}

		> ul {
			li {
				&:last-child {
					border-top: none;
				}
			}
		}

		.goal-end-point {
			ul {
				li {
					&:first-child {
						border-top: none;
					}
				}
			}
		}
	}
}

#table_documents_uploaded {
	margin-bottom: 20px !important;
}

#table_documents_uploaded td {
	text-align: left !important;
	padding: 6px !important;
	margin-bottom: 20px !important;
}

.text-gray-light {
	color: #bbbbbb !important;
}

.total-users .goal-status ul:last-child li {
	border-top: 1px solid #ecf3fa !important;
}

.form_upload_documents hr {
	border-bottom: 1px solid #ecf3fa !important;
	opacity: 1 !important;
	background: transparent;
}

.form_upload_documents button {
	width: 100% !important;
	max-width: 190px;
}

.dz-remove {
	bottom: -25px;
	position: absolute;
	left: 17px;
}

.form-control.is-valid,
.was-validated .form-control:valid {
	border-color: #ced4da;
	padding-right: initial;
	background-image: none;
}

.invalid-feedback {
	color: #dd4050 !important;
}

.product-box
.modal
.modal-header
.product-box
.product-details
.product-size
ul
li {
	margin-bottom: 10px !important;
}

.list-group-item {
	padding-left: 0px !important;
}

.text-black {
	color: black;
}

.flag_pin {
	height: 95px !important;
	margin-top: 16px !important;
}

.visibility-hidden {
	visibility: hidden;
}

.hr_light {
	border-top: 1px solid #cccccc;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #dbdde1 !important;
	border-color: #e7e8eb !important;
	color: #666666 !important;
	cursor: not-allowed !important;
}

.pricingtable {
	border: 0px solid #b0b0b0 !important;
}

.pricingtable > .pricingtable-header > h4 {
	margin-bottom: 10px !important;
}

.pricingtable > hr {
	margin-top: 20px !important;
	border-top: 1px solid white !important;
	opacity: 0.6 !important;
}
.table_ranks_before hr{
	border-top: 1px solid white !important;
	opacity: 0.6 !important;
	display: none;
}

.pricingtable > .description {
	margin: auto;
	width: 70%;
	// min-height: 200px;
}

.pricingtable > .description > p:last-child {
	margin-top: 10px;
}

.pricingtable > .description > p:first-child {
	margin-top: 20px;
	margin-bottom: 0px !important;
}

.pricingtable > .description > p > .size-11 {
	font-size: 11px;
}
#owl-carousel-carriera .item{
	width: 100% !important;
}

.pricingtable > .table_ranks {
	margin: auto !important;
	width: 85% !important;
	margin-top: 0px !important;
	/*min-height: 160px !important;*/
}

.pricingtable > .table_ranks_before {
	margin: auto !important;
	width: 90% !important;
	margin-top: 0px !important;
}
.pricingtable > .table_ranks_before td {
	background-color: rgba(0,0,0,0.05);
}
.pricingtable > .description {
	min-height: 170px;
}

.pricingtable > .table_ranks > tbody > tr > td {
	vertical-align: center;
	text-align: left !important;
}

.pricingtable > .table_ranks > tbody > tr > td:first-child {
	text-align: left !important;
	padding-right: 15px !important;
}

.pricingtable > .table_ranks > tbody > tr > td:last-child {
	text-align: left !important;
	padding-left: 0px !important;
}

.pricingtable > .table_ranks > tbody > tr > td[colspan]:last-child {
	text-align: left !important;
	padding-right: 0px !important;
	padding-left: 0px !important;
}
.pricingtable > .table_ranks_footer {
	margin: auto !important;
	width: 85% !important;
}
.pricingtable > .table_ranks_footer p{
	color: #80784a !important;
	padding-top: 15px !important;
}

.pricingtable .pricing-content {
	max-width: 300px !important;
	text-align: justify !important;
}

.pricingtable.current_role {
	border: 1px solid #333333 !important;
}

.pricingtable .pricing-content li {
	font-weight: 300;
	line-height: 1.3 !important;
}

.pricingtable hr {
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 4px !important;
	margin-bottom: 4px !important;
	border-top: 1px solid #b0b0b0;
	border-bottom: 1px solid #b0b0b0;
	border-bottom: none;
	width: 96%;
	margin: auto;
	text-align: center;
}

.pricingtable hr.middle {
	width: 100% !important;
	border-top: 2px solid #fff !important
}

.pricingtable .pricing-content li.not_reached:before {
	content: "\f096";
	color: #b0b0b0 !important;
	font-family: FontAwesome;
	text-align: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	left: 0;
}

.pricingtable .pricing-content li.not_reached {
	color: #b0b0b0 !important;
}

.pricingtable .pricing-content li.trophy:before {
	content: "\f091" !important;
	font-family: FontAwesome;
	text-align: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: absolute;
	top: 1px;
	left: 0;
}

#owl-carousel-carriera > div > div > div > div > .pricingtable::before, #owl-carousel-carriera > div > div > div > div > .pricingtable::after {
	content: "";
	background-color: var(--theme-tertiary) !important;
}

.bkg-primary-alpha-step-1 {
	background-color: rgba(111, 189, 132, 0.1) !important;
}

.bkg-primary-alpha-step-2 {
	background-color: rgba(111, 189, 132, 0.2) !important;
}

.bkg-primary-alpha-step-3 {
	background-color: rgba(111, 189, 132, 0.3) !important;
}

.bkg-primary-alpha-step-4 {
	background-color: rgba(111, 189, 132, 0.4) !important;
}

.bkg-primary-alpha-step-5 {
	background-color: rgba(111, 189, 132, 0.5) !important;
}

.bkg-primary-alpha-step-6 {
	background-color: rgba(111, 189, 132, 0.6) !important;
}

.bkg-primary-alpha-step-7 {
	background-color: rgba(115, 91, 163, 0.1) !important;
}

.bkg-primary-alpha-step-8 {
	background-color: rgba(115, 91, 163, 0.2) !important;
}

.bkg-primary-alpha-step-8 {
	background-color: rgba(115, 91, 163, 0.25) !important;
}

.bkg-primary-alpha-step-9 {
	background-color: rgba(115, 91, 163, 0.3) !important;
}

.bkg-primary-alpha-step-10 {
	background-color: rgba(115, 91, 163, 0.4) !important;
}
.bkg-primary-alpha-step-11 {
	background-color: rgba(115, 91, 163, 0.5) !important;
}
.icon-ranks-career{
	max-width: 100px !important;
	margin:auto;
}

.bkg-carriera-1{background-color: #f8f8f8 !important;}
.bkg-carriera-2{background-color: #f8f8f8 !important;}
.bkg-carriera-3{background-color: #f8f8f8 !important;}
.bkg-carriera-4{background-color: #f8f8f8 !important;}
.bkg-carriera-5{background-color: #f8f8f8 !important;}
.bkg-carriera-6{background-color: #f8f8f8 !important;}
.bkg-carriera-7{background-color: #f8f8f8 !important;}
.bkg-carriera-8{background-color: #f8f8f8 !important;}
.bkg-carriera-9{background-color: #f8f8f8 !important;}
.bkg-carriera-10{background-color: #f8f8f8 !important;}

//.bkg-carriera-1 h4{color: #FFFFFF !important;}
//.bkg-carriera-1 .description p{color: #FFFFFF !important;}
//.bkg-carriera-1 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-1 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-2 h4{color: #FFFFFF !important;}
//.bkg-carriera-2 .description p{color: #FFFFFF !important;}
//.bkg-carriera-2 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-2 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-3 h4{color: #FFFFFF !important;}
//.bkg-carriera-3 .description p{color: #FFFFFF !important;}
//.bkg-carriera-3 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-3 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-4 h4{color: #FFFFFF !important;}
//.bkg-carriera-4 .description p{color: #FFFFFF !important;}
//.bkg-carriera-4 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-4 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-5 h4{color: #FFFFFF !important;}
//.bkg-carriera-5 .description p{color: #FFFFFF !important;}
//.bkg-carriera-5 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-5 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-6 h4{color: #FFFFFF !important;}
//.bkg-carriera-6 .description p{color: #FFFFFF !important;}
//.bkg-carriera-6 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-6 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-7 h4{color: #FFFFFF !important;}
//.bkg-carriera-7 .description p{color: #FFFFFF !important;}
//.bkg-carriera-7 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-7 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-8 h4{color: #FFFFFF !important;}
//.bkg-carriera-8 .description p{color: #FFFFFF !important;}
//.bkg-carriera-8 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-8 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-9 h4{color: #FFFFFF !important;}
//.bkg-carriera-9 .description p{color: #FFFFFF !important;}
//.bkg-carriera-9 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-9 .table_ranks td{color: #FFFFFF !important;}
//
//.bkg-carriera-10 h4{color: #FFFFFF !important;}
//.bkg-carriera-10 .description p{color: #FFFFFF !important;}
//.bkg-carriera-10 .table_ranks_before td{color: #FFFFFF !important;}
//.bkg-carriera-10 .table_ranks td{color: #FFFFFF !important;}

.cursor_normal {
	cursor: auto !important;
}

.item_footer {
	bottom: 20px;
	position: absolute;
	margin: auto;
	text-align: center;
	width: 98%;
}

.color-black {
	color: black !important;
}

#table_counter_mail td {
	padding-right: 15px !important;
	border-bottom: 1px solid #e0e0e0;
	color: gray;
}

.icon_name_document {
	width: min-content !important;
}

.icon_name_document td:nth-child(2) {
	padding-left: 15px !important;
}

.file-box {
	display: inline-grid !important;
	cursor: pointer !important;
}

.file-box:hover {
	background: #dedede !important;
}

.folder-box {
	display: inline-grid !important;
	cursor: pointer !important;
}

.folder-box:hover {
	background: #dedede !important;
}

#table_product_info td:nth-child(2) {
	padding-left: 15px !important;
}

.login-card .login-main .theme-form .or:before {
	width: 50% !important;
}

.login-card {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	background: url("../images/login/bg_login.jpg");
	background-position: center;
	padding: 30px 12px;
	background-size: cover;
}

.img_circle_centered {
	width: 100%;
	position: absolute;
	text-align: center;
	margin-top: -100px;
	top: 0px;
}

.img_circle_centered img {
	width: 150px;
	height: 150px;
	border: 4px solid #ffffff;
}

.line {
	color: #efefef;
	opacity: 1;
}

#documents, #row_page_dati_residenza , #row_tree_filters,  #row_page_documenti_personali{
	display: flex;
	flex-direction: row;
}

#documents > .div-side,  #row_page_dati_residenza > .div-side, #row_tree_filters > .div-side, #row_page_documenti_personali > .div-side {
	display: flex;
}

#documents > .div-main, #row_page_dati_residenza > .div-main, #row_tree_filters > .div-main, #row_page_documenti_personali > .div-main {
	display: flex;
}

#alberoDocumenti > .file-content {
	width: 100%;
}

#appendiamoQuicartelle, #appendiamoQui {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.file-content .files .file-box, .file-content .folder .folder-box {
	display: flex;
	margin-right: 10px;
	margin-bottom: 10px;
}

.file-sidebar > li {
	padding-bottom: 10px;
}

table.icon_name_document {
	width: 80% !important;
}

.visible-xxs, .visible-xs , .visible-sm{
	display: none !important;
}

.top5-ultimi-iscritti {
	padding-top: 15px;
}

#featured_news, #other_news {
	display: flex;
	flex-direction: row;
	align-content: stretch;
	align-items: stretch;
	flex-wrap: wrap;
}

#featured_news > div.div-main, #other_news > div.div-main {
	display: flex;
}

#featured_news > div.div-main > a, #other_news > div.div-main > a {
	display: flex;
	align-self: auto;
	align-content: stretch;
	align-items: stretch;
	width: 100%;
}

#featured_news > div.div-main > a > div.card, #other_news > div.div-main > a > div.card {
	width: 100%;
}

#featured_news > div.div-side {
	display: flex;
	flex-direction: column;
	align-content: stretch;
	align-items: stretch;
}

.d-flex {
	display: flex !important;
}

.flex-column {
	flex-display: column;
}

.flex-row {
	flex-direction: row;
}

.w-100 {
	width: 100% !important;
}

.padding-card-body {
	padding: 15px 40px !important;
}

/*.cal-date{
    border-radius: 10px !important;
    left: 6px !important;
    top: 6px !important;
}*/

.cal-date {
	position: relative !important;
	top: -1% !important;
	width: 100px;
	height: 100px;
	margin: 0 auto;
	text-align: center;
	background-color: #fff !important;
	border-radius: 50% !important;
	margin-top: -45px !important;
	border: 1px solid #ecf3fa;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}


.cal-date > h5 {
	color: #000 !important
}

table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
	width: 98% !important;
}

//.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
//	margin-top: -20px !important;
//}
@media (max-width: 1440px) {
	.img_circle_centered {
		margin-top: -60px;
	}
	.img_circle_centered img {
		width: 100px;
		height: 100px;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: -20px !important;
	}
}

@media (max-width: 1200px) {
	.img_circle_centered {
		margin-top: -70px;
	}
	.img_circle_centered img {
		width: 100px;
		height: 100px;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: -20px !important;
	}
}

@media (max-width: 992px) {
	.visible-sm {
		display: block !important;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: -20px !important;
	}

	.img_circle_centered {
		margin-top: -70px;
	}
	.img_circle_centered img {
		width: 100px;
		height: 100px;
	}
}

@media (max-width: 768px) {
	.col-xs-12{
		width: 100% !important;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: -40px !important;
	}

	.visible-xs {
		display: block !important;
	}

	.img_circle_centered {
		margin-top: -115px;
	}
	.img_circle_centered img {
		width: 100px;
		height: 100px;
	}
}

@media (max-width: 576px) {
	.visible-xxs {
		display: block !important;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: -40px !important;
	}

	.img_circle_centered {
		margin-top: -60px;
	}
	.img_circle_centered img {
		width: 60px;
		height: 60px;
	}

	.pricingtable > .table_ranks {
		width: 90% !important;
	}
}

.pencil-wrapper {
	position: absolute;
	bottom: -3px;
	left: 51%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background-color: var(--theme-deafult);
	color: #fff;
	overflow: hidden;
	margin: 0 auto;
	font-size: 17px;
	cursor: pointer;
}

.bg-transparent {
	background: transparent !important;
}

.display-table-cell-hidden-mobile {
	display: table-cell !important;
}

@media (max-width: 576px) {
	.display-table-cell-hidden-mobile {
		display: none !important;
	}
	.order-history table {
		width: 100%;
	}
}

.checkout-details {
	background-color: #fcfcfc !important;
	border: 1px solid #E0E0DF !important;
	padding: 25px !important;
}

.order-box .qty {
	border-bottom: none !important;
	margin-bottom: 0px !important;
}

.order-box .total li {
	font-weight: 600 !important;
}

.order-box .sub-total li .count {
	font-weight: 600 !important;
}

.order-box .qty li span {
	font-size: 14px !important;
}

.chosen_method {
	border: 1px solid #eaeaea;
	width: 100%;
	padding: 15px;
	font-size: 15px;
}

.chosen_method label {
	margin-bottom: 0px !important;
}

.chosen_method p {
	font-size: 15px !important;
}

.chosen_method td {
	vertical-align: middle !important;
}

.chosen_method table {
	width: 100%;
}

.chosen_method table td:nth-child(1) {
	width: 20px;
}

.chosen_method table td:nth-child(3) {
	width: 100px;
}

.chosen_method hr {
	background-color: #eaeaea !important;
}

#div_stores table td {
	width: 50%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: none !important;
}

.default-according .card .btn-link {
	font-weight: 400 !important;
	font-size: 13px !important;
}

.table-td-vertical-align-middle td {
	vertical-align: middle !important;
}

#best-products hr {
	border: none;
	border-bottom: 1px solid #ecf3fa;
	opacity: 1;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

#reorder-products hr {
	border: none;
	border-bottom: 1px solid #ecf3fa;
	opacity: 1;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.price-value-free .duration {
	font-size: 16px !important;
}

.price-value-free {
	background-color: var(--theme-secondary) !important;
}

.font-size-15 {
	font-size: 15px !important;
}

.display-inline {
	display: inline !important;
}

@media (min-width: 769px) {
	.login-card .div_registrazione {
		width: 960px;
	}
}

.overflow_agreement {
	max-height: 100px;
	overflow-y: scroll;
}

.scroll-demo {
	height: auto !important;
}

#filters_datatable.datatable_struttura input {
	padding-top: 10px;
	padding-bottom: 9px;
}

#filters_datatable.datatable_struttura select {
	font-size: 13px !important;
	padding-top: 10px;
	padding-bottom: 9px;
}

// additional.css
#lingue_front {
	position: absolute;
	top: 15px;
	right: 15px;
	text-align: right;
	padding: 15px;
	z-index: 999;
	width: 100px !important;
}

//#endregion

//#region css dashboard restyle

.page-wrapper
.page-header
.header-wrapper
.nav-right
.onhover-show-div
li
+ li:first-child {
	border-top: 0px solid #f5f5f5;
}

.page-wrapper.compact-wrapper
.page-body-wrapper
div.sidebar-wrapper
.sidebar-main
.sidebar-links
.simplebar-wrapper
.simplebar-mask
.simplebar-content-wrapper
.simplebar-content
> li:hover
.sidebar-link:not(.active):hover
span {
	color: var(--theme-deafult);
	color: #fff !important;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.show-sottoposti-sm > .clear {
	display: none;
}

.show-sottoposti-sm > h3 {
	position: relative;
	top: 3px;
}

#typehead_ID_utente {
	/* background-color: #fff !important;  */
	/* border: 1px solid rgba(var(--theme-deafult-rgb), 0.8); */

	/*background-color: rgba(var(--theme-deafult-rgb), 0.17) !important;*/
	background-color: rgba(var(--theme-secondary-rgb), 0.17) !important;
	margin-left: 15px;
	border-radius: 4px;
	position: relative;
	margin-top: -3px;
	box-shadow: 0 0 20px rgba(8, 21, 66, 0.05);
}

#type-sottoposti.ingresso:focus {
	box-shadow: none !important;
	background-color: transparent !important;
}

#type-sottoposti.ingresso::placeholder {
	color: rgba(var(--theme-deafult-rgb), 0.65);
}

#typehead_ID_utente i {
	color: var(--theme-deafult);
}

.typehead_utente_dashboard,
.typehead_utente_dashboard:focus {
	color: var(--theme-deafult);
	background-color: transparent !important;
}

#div_totali > div:last-child > div.media {
	border-right: 0px solid transparent !important;
}

#top5_carriera_sponsor .row {
	align-items: center;
	align-content: stretch;
}

#div_totali > div:last-child > div.media > div > div > hr {
	display: none !important;
	background-color: transparent !important;
}

.sidebar-link.active > span {
	color: #fff !important;
}

.sidebar-mobile-img {
	height: 80px;
	padding: 20px 20px !important;
}

.sidebar-mobile-img > a > img {
	max-width: 150px !important;
	margin-top: 10px;
}

.twitter-typeahead > input {
	padding: 0px 15px;
}

hr.d-block {
	background-color: #eee9e2;
	opacity: 1;
}

.sidebar-title{
	margin-bottom: 10px !important;
}

.sidebar-title:hover {
	/*background-color: var(--theme-deafult) !important;*/
	background-color: #b00e66 !important;
}

.sidebar-title.active {
	/*background-color: var(--theme-deafult) !important;*/
	background-color: #b00e66 !important;
}

.profile-media > .media-body.small {
	display: none !important;
}

.sidebar-img > a > img {
	/*width: auto;
	height: 65px;*/
	max-width: 190px;
	margin-top: 14px;
}

.tt-menu {
	width: calc(100% + 40px) !important;
}

.sidebar-link.active svg {
	color: #fff !important;
}

.sidebar-link:not(.active):hover svg {
	stroke: #fff !important;
}

.btn-light {
	background-color: #f4f4f4 !important;
	color: var(--theme-deafult) !important;
}

.btn-light:hover {
	background-color: #e0e0e0 !important;
	color: var(--theme-deafult) !important;
}

.btn-primary {
	background-color: var(--theme-deafult) !important;
	color: #fff !important;
}

.btn-primary:hover {
	background-color: var(--theme-deafult-darker) !important;
	color: #fff !important;
}

.earning-card.card .card-body .inner-top-right ul li:last-child::before {
	background-color: var(--theme-secondary) !important;
}

#row_data {
	display: flex;
	align-content: flex-start;
	flex-wrap: wrap;
	align-items: stretch;
}

#row_data > .column-left {
	display: flex;
}

#row_data > .column-right {
	align-items: stretch;
	align-content: stretch;
	display: flex;
	flex-direction: column !important;
}

.idContainerAlbero > div.card-header {
	padding: 20px 40px !important;
}

#row_tree_filters {
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;
}

#row_tree_filters > div.div-side-filtri {
	display: flex;
}

#owl-carousel-invita-persone > div > div.owl-stage, #owl-carousel-carriera > div > div.owl-stage {
	display: flex !important;
	flex-direction: row !important;
}

#owl-carousel-invita-persone > div > div.owl-stage > div.owl-item, #owl-carousel-carriera > div > div.owl-stage > div.owl-item {
	display: flex !important;
}

.owl-theme .owl-nav.disabled + .owl-dots {
	margin-bottom: 10px;
}

#box_news {
	height: auto !important;
}

.text-primary {
	color: var(--theme-deafult) !important;
}

.textarea-sito {
	min-height: 275px !important;
}

.card-radius {
	border-radius: 15px !important;
}

.h-10{
	height: 10px;
}

.h-15{
	height: 15px;
}

.h-30{
	height: 30px;
}

// here the mediaqueries
@media (min-width: 1660px) {
	#container_page_ingresso .row > [class*="div-side-"] {
		flex-direction: row !important;
	}
}

@media (max-width: 1199px) {

	.pad-bottom-sm{
		padding-bottom: 20px !important;
	}

	.card-body {
		> .row {
			> div {
				.row {
					> div.pad-start {
						&:last-child {
							padding-left: calc(var(--bs-gutter-x) * .5);
						}
					}
				}
			}
		}
	}

	#row_tree_filters > div.div-side-filtri {
		flex-direction: column !important;
	}

	.show-sottoposti-sm {
		padding-top: 15px !important;
	}
}

@media (max-width: 1024px) {
	#div_profile_general_data {
		width: fit-content;
	}
	#table_profile_general_data {
		margin-top: -158px !important;
		float: right;
	}
	.profile-details h4 {
		font-size: 16px;
	}
}

@media (max-width: 768px) {

	.pad-top-sm{
		padding-top: 15px;
	}

	.no-pad-top-sm{
		padding-top: 0px;
	}

	.media-body > div {
		margin-left: 0px !important;
	}

	.width_100_sm {
		width: 100%;
	}

	.sidebar-img {
		display: none;
	}

	.sidebar-mobile-img {
		display: block !important;
		height: 60px;
		padding: 8px 8px !important;
		text-align: center;
	}

	.show-sottoposti-sm {
		display: block !important;
		padding: 0px 15px;
	}

	.show-sottoposti-sm > .clear {
		display: block;
	}

	.show-sottoposti-sm > h3 {
		display: block;
	}

	.show-sottoposti-sm > div.div-sottoposti {
		display: block;
		margin-top: 10px;
	}

	.show-sottoposti-sm > div.div-sottoposti > #typehead_ID_utente {
		float: none;
	}

	#typehead_sottoposti {
		flex-wrap: initial;
	}

	#typehead_sottoposti > span.twitter-typeahead {
		width: 87%;
	}

	#type-sottoposti,
	.typehead_utente_dashboard {
		font-size: 20px;
		/* padding: 0px 0px; */
	}

	#typehead_ID_utente {
		margin-left: 0px;
	}

	.profile-nav {
		display: block !important;
	}

	.profile-media > .media-body.large {
		display: block !important;
	}

	.profile-media > .media-body.small {
		display: none !important;
	}
}

@media (max-width: 576px) {

	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
		border-radius: 4px !important;
		background-color: rgba(var(--theme-deafult-rgb), 0.65) !important;
	}

	.col-xs-12{
		width: 100% !important;
	}

	.login-card .login-main .theme-form .link {
		position: relative !important;
		display: block !important;
		top: 0px !important;
		right: 0px !important;
	}

	.nav-menus {
		margin-right: 0px !important;
	}

	.profile-nav {
		display: none !important;
	}

	.profile-media > .media-body.large {
		display: none !important;
	}

	.profile-media > .media-body.small {
		display: block !important;
	}

	.profile-media > .media-body.small > p > i {
		font-size: 26px !important;
	}

	.profile-media > img {
		width: 36px !important;
	}
}

@media (max-width: 480px) {

	.custom_width_xxs {
		width: 82.5%;
	}

	#applied_filters_datatable_container {
		height: 30px;
		overflow: auto;
		white-space: nowrap;
		position: absolute;
		width: 200px;
		margin-top: -16px;
	}

	#datatable_responsive_orders_filter label {
		visibility: hidden;
		height: 1px;
		overflow: hidden;
	}
}

@media (max-width: 375px) {
	#div_profile_general_data {
		width: 100%;
	}
	#table_profile_general_data {
		margin-top: 0px !important;
		float: none;
	}
	.profile-details h4 {
		font-size: 16px;
	}
}

@media print {
	#div_header_print {
		display: block;
	}
	#row_tree_filters {
		background: white;
	}
	.not-printable {
		display: none;
	}
	.sidebar-wrapper {
		display: none;
	}
	.page-header {
		display: none;
	}
	.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
		margin-top: 0px;
		margin-left: 0px;
	}
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: none;
	}
	.card {
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		border-radius: 0 !important;
	}
	.page-wrapper .page-body-wrapper {
		background-color: #ffffff;
	}
	footer {
		display: none;
	}
	body {
		width: 210mm;
		min-height: 297mm;
	}
	#buttons_print_back {
		display: none;
	}
	table td h6 {
		font-size: 12px;
	}
	table td p {
		font-size: 12px;
	}
}

//#endregion

//#region css dashboard Freeko

//#endregion


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li a svg{
	/*color: rgba(0,0,0,.8) !important;*/
}
.page-wrapper .sidebar-main-title p{
	color: #ababab !important;
}
.sidebar-wrapper{
	/*background-color: #7f7c6c !important;*/
	background-color: #444343!important;

	/*background-image: linear-gradient(#D5CA82, #9d9563) !important;*/
}

.page-wrapper .sidebar-main-title>div {
	/*background-color: #DE0D7F !important;*/
	background-color: rgba(255,255,255,0.1) !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
	/*color: rgba(255,255,255,.8) !important;*/
	color: #ffffff !important;
}
.page-wrapper .sidebar-main-title h6 {
	color: #ababab !important;
	margin-top:0px;
}
.sidebar-title:hover{
	/*background-color: var(--theme-deafult) !important;*/
	background-color: #98161b !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li .sidebar-submenu li a{
	/*color: rgba(0,0,0,.8) !important;*/
	color: rgba(255,255,255,.7) !important;
	font-weight: 200 !important;
	font-family: 'Poppins', Sans-Serif !important;
}
.sidebar-title.active {
	/*background-color: #DE0D7F !important;*/
	background-color: #98161b !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
	letter-spacing: .7px;
	text-transform: capitalize;
	color: #ffffff;
}
.text-warning{
	color: #b66da3 !important;
}
.product-box .product-img{
	text-align: center !important;
	padding-top: 20px;
}
.img-70{
	width: 70px !important;
}
.td-align-left{
	text-align: left !important;
}
.td-align-center{
	text-align: center !important;
}
.td-align-right{
	text-align: right !important;
}
.order-box .sub-total li{
	font-weight: 200 !important;
	font-size: 14px !important;
	border-bottom: 1px solid #cccccc;
}
.order-box .sub-total li .count{
	font-weight: 200 !important;
	font-size: 14px !important;
}
.table_riepilogo_prodotti td{
	font-size: 14px !important;
}
.riepilogo_td_amount{
	width: 90px;
	text-align: right;
}
#checkout-modalita-spedizione label{
	display: inline !important;
}
#checkout-modalita-pagamento label{
	display: inline !important;
}
#checkout-modalita-spedizione li{
	min-height: 46px !important;
	border-bottom: 1px solid #e0e0e0;
	padding-top:12px;
}
#checkout-modalita-pagamento li{
	min-height: 46px !important;
	border-bottom: 1px solid #e0e0e0;
	padding-top:12px;
}
.order-box .title-box h4 {
	font-weight: 500 !important;
}
.i_remove{
	width: 16px;
	height: auto;
	color: darkred !important;
}
#table_cart{
	border-color: #ecf3fa !important;
}
#table_cart tr{
	border-color: #ecf3fa !important;
}
#table_cart tr:nth-last-child(3) {
	border: none !important;
}
#table_cart tr:nth-last-child(3) td {
	border: none !important;
}
#table_cart tr:nth-last-child(2) {
	border: none !important;
}
#table_cart tr:nth-last-child(2) td {
	border: none !important;
}
#table_cart tr:nth-last-child(1) {
	border: none !important;
}
#table_cart tr:nth-last-child(1) td {
	border: none !important;
}
#table_cart .btn{
	text-transform: uppercase;
	font-weight: 300;
}
#table_cart th{
	font-weight: 300 !important;
	font-size: 14px !important;
}
#table_cart .totals{
	font-size: 18px !important;
	font-weight: 400 !important;
}
.no-padding{
	padding: 0 !important
}
.table_cart_price_pc td{
	padding-left: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	text-align: left !important;
}
.table_qty_remove td{
	padding-left: 0 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	text-align: left !important;
}
.table_qty_remove td{
	width: 33% !important;
}
.table_cart_price_pc td:nth-child(1){
	width: auto;
}
.table_cart_price_pc td:nth-child(2){
	text-align: left;
}
.order-history table .qty-box {
	margin:auto;
}
#div_chart_filters label[for=Grafico_dato]{
	display: block;
}
#totali_grafico_ingresso{
	margin-top:	30px;
}
#totaliP{
	margin-left: unset;
}
#div_profile_general_data{
	min-height: unset;
	margin: unset;
}
ol.breadcrumb{
	display: flex;
	flex-wrap: wrap;
}
/*.page-wrapper .page-body-wrapper .page-title {
padding: 35px 35px 20px 14px !important;
}*/
@media (max-width: 576px) {
	.order-history table .qty-box {
		margin:unset;
	}
	.table_qty_remove td{
		padding-left: 0 !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		text-align: right !important;
	}
	.table_qty_remove td:nth-child(1) {
		width: 60%;
	}
	.table_cart_price_pc td:nth-child(1){
		width: 60%;
		text-align: left;
	}
	.table_cart_price_pc td:nth-child(2){
		text-align: right;
	}
	#div_totali .col-sm-6{
		width: 50%;
	}
	#div_chart_filters label[for=Grafico_dato]{
		display: none;
	}
	#totali_grafico_ingresso{
		margin-top:	0px;
	}
	#totaliP{
		margin-left: 15px;
	}
	#div_profile_general_data{
		min-height: 300px;
		margin: auto;
	}
	ol.breadcrumb{
		display: none;
	}
	.page-wrapper .page-body-wrapper .page-title {
		padding: 0px 35px 20px 14px !important;
	}
}

#card-riepilogo{
	position: fixed !important;
	margin-right: 25px !important;
}
#header-sito-personale{
	height: 200px;
}

@media (max-width: 1440px) {
	#card-riepilogo{
		position: fixed !important;
		margin-right: 25px !important;
	}
}

@media (max-width: 1200px) {
	#card-riepilogo{
		position: fixed !important;
		margin-right: 25px !important;
	}
}

@media (max-width: 992px) {
	#card-riepilogo{
		position: fixed !important;
		margin-right: 25px !important;
	}
}

@media (max-width: 768px) {
	#card-riepilogo{
		position: fixed !important;
		margin-right: 25px !important;
	}
}

@media (max-width: 576px) {
	#card-riepilogo{
		position: relative !important;
		margin-right: unset !important;
	}
	#header-sito-personale{
		height: auto;
	}
	.img_circle_centered{
		position: relative;
	}
	.padding-card-body {
		padding: 15px 15px !important;
	}
}
#card-riepilogo{
	background-color: #fbfbfb !important;
}
#card-riepilogo .card-header{
	background-color: #fbfbfb !important;
}
.btn-extrasmall{
	padding:3px !important;
	font-size: 12px !important;
}
.font-size-16{
	font-size: 16px !important;
}
#loader-overlay{
	position: fixed;
	background-color: rgba(127,127,127,0.7);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0.7;
	z-index:10;
	display: none;
}
#loader-overlay div{
	position: relative;
	top: 45%;
}
#table_my_shipping_addresses i{
	height: 16px !important;
	width: 16px !important;
}
#table_my_shipping_addresses tr:hover{
	background-color: #fafafa;
}
.td_icon_feather{
	width: 35px !important;
}

#header-codice{
	position: absolute;
	width: 500px;
	margin-top: -10px !important;
}
select.form-select{
	//background-size: auto !important;
}
#agreements h6{
	font-size: 14px !important;
	font-weight: 300 !important;
}
#agreements hr{
	border-top: 1px solid #CCCCCC !important;
	font-weight: 300 !important;
}
h1,h2,h3,h4,h5,h6,p,div,th,td,span,option,select,label,input,textarea{
	font-family: Poppins !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li a svg{
	color: #ffffff !important;
}
.custom-card .card-header img {
	border-radius: unset !important;
	-webkit-transform: scale(1.1) !important;
}
.bg-warning{
background-color: #da3a87 !important;
}
.input-invalid{
	padding-right: calc(1.5em + 0.75rem);
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.height_100{
	height: 100%;
}
.max_height_card_body_ingresso{
	max-height: unset;
}
#icon-cart-added{
	width: 140px !important;
}
fieldset.qty-box{
	margin-left: 40% !important;
}

.dataTable td{
	font-family: 'Poppins', sans-serif !important;
	font-size: 13px !important;
}
.dataTable th{
	font-family: 'Poppins', sans-serif !important;
	font-size: 13px !important;
}
#riepilogo-totali-bonus td{
	font-family: 'Poppins', sans-serif !important;
	font-size: 13px !important;
}
#modulo_dati_spedizione{
	display: none;
}
#modulo_dati_spedizione_footer{
	display: none;
}
#modulo_dati_spedizione_footer2{
	display: none;
}
.div_totali_value h4{
	font-family: 'Poppins', sans-serif !important;
	font-size: 18px !important;
}
.totale_titolo{
	color: #9a9a9a !important;
	max-width: 100% !important;
}
.form-control{
	font-size: 14px !important;
}
.form-select{
	font-size: 14px !important;
}
.table.dataTable td{
	font-size: 13px !important;
}
.dataTables_wrapper button {
	font-size: 12px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
	width: 250px;
	font-size: 12px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
	padding: 10px !important;
	border-bottom: 1px solid #e0e0e0 !important;
}
#datatable_responsive_orders_length{
	margin-top: 1px !important;
	margin-left: 10px !important;
}

#datatable_responsive_orders_info{
	margin-left: 14px !important;
}
.iframe_share_social{
	height: 30px;
}
.container_iframe_share_social{
	min-height: 40px;
}
#img-no-sponsor-account{
	margin-top: -10 !important;
}
.table_ranks tr{
	border-bottom: 1px solid #cccccc;
}
.table_ranks td{
	font-size: 13px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.text-waiting{
	color: orange !important;
}
#datatable_responsive_bonus .dt-right{
padding-right: 0px !important;
}
.page-wrapper .page-body-wrapper {
	margin-top: 80px !important;
}
#datatable_responsive_carriera th:nth-child(1){
	border-right:1px solid #e0e0e0;
}
#datatable_responsive_carriera td:nth-child(1){
	border-right:1px solid #e0e0e0;
}
#datatable_responsive_carriera th:nth-child(2){
	border-right:1px solid #e0e0e0;
}
#datatable_responsive_carriera td:nth-child(2){
	border-right:1px solid #e0e0e0;
}
#datatable_responsive_carriera th:nth-child(6){
border-right:1px solid #e0e0e0;
}
#datatable_responsive_carriera td:nth-child(6){
	border-right:1px solid #e0e0e0;
}
.f-w-b{
	font-weight: bold !important;
}
#datatable_responsive_carriera th{
	font-size: 12px !important;
}
#datatable_responsive_carriera td{
	font-size: 12px !important;
}
.pricingtable{
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.simplebar-content>li .sidebar-submenu li a:after{
	border-top: 2px solid #948f76 !important;
}
.toggle-sidebar svg {
	color: #948f76 !important;
}
.logo-login{
	max-width: 250px !important;
}
#img-no-sponsor-account{
	width: 100% !important;
}
#criterio_attivita_prodotti .criterio_attivita_prodotto{
	border:1px solid #e0e0e0;
	border-radius: 10px;
	text-align: center;
	margin-bottom: 15px;
	padding-top: 15px;
	padding-bottom: 15px;
	background: #f8f8f8;
}
a.logo img{
	max-width: 180px !important;
	margin: auto;
}
#datatable_responsive_bonus_length{
	margin-left: 4px !important;
}
.custom-card .card-profile img {
	height: 80px;
	padding: 7px;
	background-color: #fff;
	z-index: 1;
	position: relative;
	width: auto;
	border-radius: 10px !important;
	margin-bottom: 15px;
	border:2px solid white;
	margin-top: -20px;
}
#table_loghi{
	width: max-content;
	margin: auto;
	display: block;
}

#table_loghi td{
	text-align: center;
}
#table_loghi_mobile td{
	text-align: center;
}

#table_loghi_mobile{
	width: max-content;
	margin: auto;
	display: none;
}
.sidebar-mobile-img img{
	width: 40px;
}

@media (max-width: 540px) {
	#table_loghi{
		width: max-content;
		margin: auto;
		display: none;
	}

	#table_loghi_mobile{
		width: max-content;
		margin: auto;
		display: block;
		margin-bottom: 30px;
	}
	.sidebar-mobile-img img{
		width: max-content;
	}

}
